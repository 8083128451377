import { ApplicationConfig, importProvidersFrom, inject, provideAppInitializer } from "@angular/core";
import { provideRouter } from "@angular/router";

import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AuthService } from "./services/authentication/auth.service";
import { AuthGuard } from "./services/authentication/auth-guard";
import {
  HttpClientModule,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
  withXsrfConfiguration,
} from "@angular/common/http";
import { authInterceptor } from "./services/authentication/auth-interceptor";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { environment } from "../environments/environment";
import { provideClientHydration } from "@angular/platform-browser";
import { SsrService } from "./services/ssr-service";
import { noCacheInterceptor } from "./interceptors/no-cache-interceptor";

function getBaseHref(ssrService: SsrService): string {
  const location = ssrService.getLocation();
  if (location?.pathname.startsWith("/en")) {
    return "/en";
  } else if (location?.pathname.startsWith("/fr")) {
    return "/fr";
  } else if (location?.pathname.startsWith("/lb")) {
    return "/lb";
  } else {
    return "";
  }
}

function initializeKeycloak(keycloak: KeycloakService, ssrService: SsrService) {
  const origin = ssrService.getLocation();
  if (origin) {
    return () => {
      const redirectUrl = `${origin.protocol}//${origin.host}${getBaseHref(
        ssrService
      )}/silent-check-sso.html`;
      return keycloak.init({
        config: {
          url: environment.KEYCLOAK_URL,
          realm: environment.KEYCLOAK_REALM,
          clientId: environment.KEYCLOAK_CLIENT_ID,
        },
        initOptions: {
          checkLoginIframe: true,
          onLoad: "check-sso",
          silentCheckSsoRedirectUri: redirectUrl,
        },
        shouldAddToken: (request) => {
          const { method, url } = request;
          // Only add the token to calls on our backend. Not any external
          return url.startsWith(environment.BACKEND_URL);
        },
      });
    };
  } else {
    return () => {
      return new Promise<Boolean>((resolve, reject) => {
        resolve(true);
      });
    };
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(
      withFetch(),
      withInterceptors([authInterceptor, noCacheInterceptor]),
      withXsrfConfiguration({}),
      withInterceptorsFromDi() // this is needed for the legacy keycloak. CHeck how it can be replaced
    ),
    importProvidersFrom(KeycloakAngularModule),
    AuthService,
    AuthGuard,
    provideAppInitializer(() => {
        const initializerFn = (initializeKeycloak)(inject(KeycloakService), inject(SsrService));
        return initializerFn();
      }),
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" }, // to change format of dates to DD/MM/YYYY instead of MM/DD/YYYY
  ],
};
