import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { DataService } from "../../../services/data.service";
import { SpinnerService } from "../../../services/spinner.service";

@Component({
    selector: "app-overlay-spinner",
    imports: [CommonModule, MatProgressBarModule],
    templateUrl: "./overlay-spinner.component.html",
    styleUrls: ["./overlay-spinner.component.scss"]
})
export class OverlaySpinnerComponent {
  constructor(
    public dataService: DataService,
    public spinnerService: SpinnerService
  ) {}
}
