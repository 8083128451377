<!-- Notifications -->
<button
  type="button"
  id="userMenuDropdownButton"
  data-dropdown-toggle="userMenuDropdown"
  class="relative flex items-center text-base pe-1 font-medium text-gray-900 rounded-lg hover:text-primary-600 dark:hover:text-primary-500 md:me-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white"
>
  <span i18n class="sr-only">Open User Menu</span>
  <!-- User outline -->
  @if (authService.isLoggedIn) {
    <img
      class="w-8 h-8 me-2 rounded-full"
      [attr.src]="userService.getMyImageUrl()"
      alt="Profile"
    />
  } @else {
    <svg
      class="w-6 h-6 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        stroke-width="2"
        d="M7 17v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3Zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  }
  <span i18n class="hidden sm:inline">Account</span>
  <svg
    class="w-2.5 h-2.5 ms-3"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m1 1 4 4 4-4"
    />
  </svg>
  @if (chatService.unreadMessages) {
    <div
      class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900"
    ></div>
  }
</button>

<div
  id="userMenuDropdown"
  class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
>
  @if (authService.isLoggedIn) {
    <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
      <div class="font-medium">
        {{ userService.user?.first_name }} {{ userService.user?.last_name }}
      </div>
      <div class="truncate">{{ userService.user?.email }}</div>
    </div>
    <ul
      class="py-2 text-base text-gray-700 dark:text-gray-200"
      aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
    >
      @if (propertyService.hasProperties || paymentService.canSubmit()) {
        <li>
          <a
            (click)="closeDropdown()"
            i18n
            routerLink="/my-properties"
            href="/my-properties"
            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >My Properties</a
          >
        </li>
      }
      <li>
        <a
          (click)="closeDropdown()"
          i18n
          routerLink="/settings"
          href="/settings"
          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Settings</a
        >
      </li>
      <li>
        <a
          i18n
          (click)="closeDropdown()"
          href="/blogs"
          routerLink="/blogs"
          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Blogs</a
        >
      </li>
      <li>
        <a
          i18n
          (click)="closeDropdown()"
          href="/pricing"
          routerLink="/pricing"
          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Pricing</a
        >
      </li>
      @if (userService.user?.is_admin) {
        <li>
          <a
            (click)="closeDropdown()"
            i18n
            href="/admin"
            routerLink="/admin"
            class="block px-4 py-2 text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >Admin</a
          >
        </li>
      }
      <li>
        <a
          i18n
          (click)="authService.logout()"
          class="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Logout</a
        >
      </li>
    </ul>
  }

  @if (!authService.isLoggedIn) {
    <ul
      class="py-2 text-base text-gray-700 dark:text-gray-200"
      aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
    >
      <li>
        <a
          i18n
          (click)="authService.login()"
          class="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Login</a
        >
        <a
          i18n
          (click)="authService.register()"
          class="cursor-pointer block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Register</a
        >
        <a
          i18n
          (click)="closeDropdown()"
          href="/pricing"
          routerLink="/pricing"
          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Pricing</a
        >
        <a
          i18n
          (click)="closeDropdown()"
          routerLink="/blogs"
          href="/blogs"
          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
          >Blogs</a
        >
      </li>
    </ul>
  }
</div>
