import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
// Social devices that are shown on the top bar
@Component({
    selector: "app-social-icons",
    imports: [CommonModule],
    templateUrl: "./social-icons.component.html",
    styleUrls: ["./social-icons.component.scss"]
})
export class SocialIconsComponent {
  @Input() iconSize: string = "";
  @Input() iconColor: string = "";
}
