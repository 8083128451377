import { Component } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
@Component({
    selector: "app-footer",
    imports: [CommonModule, RouterModule],
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {
  constructor(public dataService: DataService) {}
}
