import { CommonModule } from "@angular/common";
import { Component, Output, EventEmitter } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthService } from "../../../services/authentication/auth.service";
import { HorizontalMenuComponent } from "../menu/horizontal-menu/horizontal-menu.component";
import { UserMenuComponent } from "../user-menu/user-menu.component";
import { LangComponent } from "../lang/lang.component";
import { DataService } from "../../../services/data.service";

@Component({
    selector: "app-toolbar",
    imports: [
        CommonModule,
        RouterModule,
        HorizontalMenuComponent,
        UserMenuComponent,
        LangComponent,
    ],
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public authService: AuthService,
    public dataService: DataService
  ) {}

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }
}
