import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { ConfirmDialogModel } from "../confirm-dialog/confirm-dialog.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "app-alert-dialog",
    imports: [CommonModule, MatDialogModule, MatIconModule, MatButtonModule],
    templateUrl: "./alert-dialog.component.html",
    styleUrls: ["./alert-dialog.component.scss"]
})
export class AlertDialogComponent implements OnInit {
  public title: string;
  public messages: string[] = [];
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.title = data.title;
    this.messages = data.messages;
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
