import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { SpinnerService } from "../../services/spinner.service";
import { SsrService } from "../../services/ssr-service";

@Component({
    selector: "app-not-found",
    imports: [CommonModule, MatIconModule, MatCardModule, MatButtonModule],
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.scss"]
})
export class NotFoundComponent implements OnInit {
  constructor(
    public router: Router,
    private spinnerService: SpinnerService,
    private ssrService: SsrService,
  ) {

  }

  ngOnInit() {
    if (this.ssrService.isBrowser()) {
      this.initialise();
    }
  }

  public goHome(): void {
    this.router.navigate(["/"]);
  }

  private initialise() {
    this.spinnerService.disableSpinner();
  }
}
