import { CommonModule, DOCUMENT, Location } from "@angular/common";
import { Component, OnInit, Inject } from "@angular/core";
import { SsrService } from "../../../services/ssr-service";

@Component({
    selector: "app-cookies",
    imports: [CommonModule],
    templateUrl: "./cookies.component.html",
    styleUrls: ["./cookies.component.scss"]
})
export class CookiesComponent implements OnInit {
  private currentAcceptanceCookie: string = "cookies-accepted-09/05/24";

  private cookiesAccepted: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ssrService: SsrService,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (
      this.ssrService.getLocalStorage(this.currentAcceptanceCookie) ||
      this.ssrService.isServer()
    ) {
      this.cookiesAccepted = true;
    }
  }

  public isCookiesHidden(): boolean {
    return this.isTermsOfService() || this.cookiesAccepted;
  }

  private isTermsOfService(): boolean {
    return ["/privacy-policy", "/terms-conditions"].includes(this.location.path());
  }

  // By accepting the cookies we simply set the cookie
  public acceptCookies() {
    this.ssrService.setLocalStorage(this.currentAcceptanceCookie, "true");
    this.cookiesAccepted = true;
  }
}
