{
  "name": "Homie",
  "version": "1.1.0",
  "scripts": {
    "ng": "ng",
    "build": "ng build && node copy-proxy-server.js",
    "build_dev": "ng build --configuration development && node copy-proxy-server.js",
    "watch": "ng build --watch --configuration development",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.5",
    "@angular/cdk": "19.0.4",
    "@angular/common": "19.0.5",
    "@angular/compiler": "19.0.5",
    "@angular/core": "19.0.5",
    "@angular/forms": "19.0.5",
    "@angular/material": "19.0.4",
    "@angular/platform-browser": "19.0.5",
    "@angular/platform-browser-dynamic": "19.0.5",
    "@angular/platform-server": "19.0.5",
    "@angular/router": "19.0.5",
    "@angular/ssr": "^19.0.6",
    "@turf/boolean-point-in-polygon": "^7.2.0",
    "chart.js": "^4.4.7",
    "chartjs-plugin-annotation": "^3.1.0",
    "express": "^4.21.2",
    "flowbite": "^2.5.2",
    "flowbite-datepicker": "^1.3.2",
    "google-libphonenumber": "^3.2.40",
    "jwt-decode": "^4.0.0",
    "keycloak-angular": "^19.0.2",
    "keycloak-js": "^26.0.7",
    "leaflet": "^1.9.4",
    "leaflet-draw": "^1.0.4",
    "leaflet-gesture-handling": "^1.2.2",
    "moment": "^2.30.1",
    "ng2-charts": "^8.0.0",
    "ngx-image-compress": "^18.1.5",
    "ngx-quill": "^27.0.0",
    "rxjs": "7.8.1",
    "swiper": "^11.2.0",
    "tslib": "2.8.1",
    "wicket": "^1.3.8",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular/build": "^19.0.6",
    "@angular/cli": "^19.0.6",
    "@angular/compiler-cli": "19.0.5",
    "@angular/language-server": "^19.0.3",
    "@angular/language-service": "19.0.5",
    "@angular/localize": "19.0.5",
    "@tailwindcss/language-server": "^0.0.27",
    "@types/chart.js": "^2.9.41",
    "@types/express": "^5.0.0",
    "@types/geojson": "^7946.0.15",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/jasmine": "5.1.5",
    "@types/leaflet": "^1.9.16",
    "@types/leaflet-draw": "^1.0.11",
    "@types/node": "^22.10.5",
    "angular-eslint": "^19.0.2",
    "eslint": "^9.17.0",
    "eslint_d": "^14.3.0",
    "flowbite-typography": "^1.0.5",
    "ng-extract-i18n-merge": "^2.13.1",
    "prettier": "^3.4.2",
    "tailwindcss": "^3.4.17",
    "ts-node": "10.9.2",
    "typescript": "^5.6.3",
    "typescript-eslint": "^8.19.1",
    "typescript-language-server": "^4.3.3",
    "vscode-langservers-extracted": "^4.10.0"
  },
  "scarfSettings.enabled": false
}
